<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 my-auto" viewBox="0 0 34 34">
        <defs>
            <clipPath id="clip-path-5">
                <path class="cls-2" fill="currentColor"
                      d="M18 16a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zm-4 0a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zm9-4H11v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1zm-3-2V9a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1zm2 0h4a1 1 0 0 1 0 2h-1v13a3 3 0 0 1-3 3H12a3 3 0 0 1-3-3V12H8a1 1 0 0 1 0-2h4V9a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3z"/>
            </clipPath>
        </defs>
        <g id="Слой_2" data-name="Слой 2">
            <g clip-path="url(#clip-path-5)">
                <path fill="currentColor" class="cls-2" d="M5 5h24v24H5z"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'Trash',
}
</script>
